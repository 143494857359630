import {PageLayout} from "@components";
import { PartnersV2 } from "@components/LimsSoftware/PartnersV2";
import {JoinInstitutionsV2} from "../../landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import React from "react";
import {Demo} from ".././biopharmaceutical";
import FeatureCard, {FeatureCardLayout, FeatureCardProps} from "@components/FeatureCard/FeatureCard";
import {StaticImage} from "gatsby-plugin-image";
import * as styles from "../../products/orders/orders.module.scss";
import Margins from "@components/Margins/Margins";
import classNames from "classnames";
import { LogosAndG2Section } from "@components/Solutions/LogosAndG2Section";

export default function Research () {
    const demoProps = {
        title: "Information management software built for every process in chemistry R&D",
        subtitle: "Take your chemistry R&D to the next level with Genemod’s diverse LIMS features, such as inventory advanced search, electronic lab notebook, and AI platform.",
        srcImg: "medical-practitioner-inside-of-a-lab.png",
        disableBackgroundShapes: true,
        rightSectionStylesOverride: styles.rightSectionStylesOverride,
		getADemoOverride: classNames(styles.getADemoOverride, styles.clinicalGetADemoOverride),
        titleStylesOverride: styles.clinicalDemoTitleStylesOverride,
		subtitleStylesOverride: styles.clinicalDemoSubtitleStylesOverride,
		leftSectionOverride: styles.clinicalDemoLeftSectionOverride
    }

    const cards: FeatureCardProps[] = [
        {
            title: "Transform chemistry research with cutting-edge solutions",
            list: [
                "Efficiently manage chemical samples and reagents, crucial for streamlined research and experimentation.",
                "Utilize Genemod’s inventory system for accurate tracking and optimal handling of chemical resources.",
                "Employ Genemod's electronic lab notebook for organized, accessible documentation of chemical experiments and findings.",
            ],
            image: (
                <StaticImage
                    src={"../../../pages/products/lab-protocol/_assets/protocols_image_2.png"}
                    alt="Easily Manage and Submit Orders"
                />
            ),
            className: styles.featureQuickAccess,
			listItemWeight: "SEMIBOLD",
			titleStylesOverride: styles.featureTitleStylesOverride,
        },
        {
            title: "How Genemod accelerates chemistry research and development",
            list: [
                "With Genemod’s LIMS, Chemistry laboratories can efficiently manage a variety of data, from research findings and sample tracking to test results, quality checks, and analytical processes.",
                "Utilize Genemod’s virtual freezers for proficient storage, documentation, and management of materials, and enhance team collaboration by sharing data, reducing time on secondary work.",
                "Optimize your lab's consumables organization with Genemod. Manage lab resources effectively through a software system designed to record all necessary specifics.",
            ],
            image: (
                <StaticImage
                    src={"../../../assets/products/single-source-of-truth.png"}
                    alt="Collaborate for In-depth Order Tracking"
                />
            ),
            orientation: "right",
            className: styles.featureQuickAccess,
            sublistColor:'text-helper',
			listItemWeight: "SEMIBOLD",
			titleStylesOverride: styles.featureTitleStylesOverride,
        },
    ];

    return (
        <PageLayout
            seoOptions={{ type: "PREDEFINED", pageName: "research" }}
        >
           <Margins className={styles.demoMargins}>
				<Demo {...demoProps} />
			</Margins>
            <Margins className={styles.featureMargins}>
				<FeatureCardLayout className={styles.featuresLayout}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index.toString()} />;
					})}
				</FeatureCardLayout>
			</Margins>
           <LogosAndG2Section/>
			<Margins className={styles.institutionsMargins}>
            <JoinInstitutionsV2
					outerContainerClassName={
						styles.institutionsOuterContainerClassName
					}
				/>
			</Margins>            
        </PageLayout>
    );
}